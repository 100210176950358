import React, { useState } from 'react';

import "./ExpenseItem.css";
import Card from "../UI/Card";
import ExpenseDate from "./ExpenseDate";

function ExpenseItem(props) {
  const month = props.date.toLocaleString("en-US", { month: "long" });
  const day = props.date.toLocaleString("en-US", { day: "2-digit" });
  const year = props.date.getFullYear();

  const [title, setTitle] = useState(props.title);
  
  const btnClicked = () => {
    console.log("clicked");

    setTitle('Updated - ' + (new Date()).getSeconds());

  };

  return (
    <li>
      <Card className="expense-item">
        <ExpenseDate month={month} day={day} year={year} />
        <div className="expense-item__description">
          <h2>{title}</h2>
          <div className="expense-item__price">${props.price}</div>
        </div>
        <button onClick={btnClicked}>Change Title</button>
      </Card>
    </li>
  );
}

export default ExpenseItem;