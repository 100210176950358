import Chart from "../Chart/Chart";

import './ExpensesChart.css';

const ExpensesChart = (props) => {

    const chartDataPoints = [
        {label: 'Jan', value:0},
        {label: 'Feb', value:0},
        {label: 'Mar', value:0},
        {label: 'Apr', value:0},
        {label: 'May', value:0},
        {label: 'Jun', value:0},
        {label: 'Jul', value:0},
        {label: 'Aug', value:0},
        {label: 'Sep', value:0},
        {label: 'Oct', value:0},
        {label: 'Nov', value:0},
        {label: 'Dec', value:0},
    ];

    for (const exp of props.expenses){
        const expenseMonth = exp.date.getMonth();
        chartDataPoints[expenseMonth].value += exp.amount;
    }
    
    // let maxValue = 0;
    // for (const dataPoint of chartDataPoints){
    //     if (dataPoint.value > maxValue){
    //         maxValue = dataPoint.value
    //     }
    // }

    return <Chart dataPoints={chartDataPoints} />
};

export default ExpensesChart;
