import React, { useState } from "react";

import "./ExpenseForm.css";

const ExpenseForm = (props) => {
  const [expTitle, setTitle] = useState("");
  const [expAmount, setAmount] = useState("");
  const [expDate, setDate] = useState("");

  const txtTitle_Change = (event) => {
    setTitle(event.target.value);
    //console.log(expTitle);

    //setUserInput((prevState) => { return { ...prevState, expTitle: event.target.value} });
    //console.log(userInput.expTitle);
  };
  const txtAmount_Change = (event) => {
    setAmount(event.target.value);
    //console.log(expAmount);

    //setUserInput((prevState) => { return { ...prevState, expAmount: event.target.value} });
    //console.log(userInput.expAmount);
  };
  const txtDate_Change = (event) => {
    setDate(event.target.value);
    //console.log(expDate);

    //setUserInput((prevState) => { return { ...prevState, expDate: event.target.value} });
    //console.log(userInput.expDate);
  };

  const form_Submit = (event) => {
      event.preventDefault();

      const expenseData = {
          title: expTitle,
          amount: +expAmount,
          date: new Date(expDate)
      };
      if (typeof props.onSaveExpenseData !== typeof undefined){
        props.onSaveExpenseData(expenseData);
      }

      setTitle('');
      setAmount('');
      setDate('');
      
      console.log(expenseData);
  };

  return (
    <form onSubmit={form_Submit}>
      <div className="new-expense__controls">
        <div className="new-expense__control">
          <label>Title</label>
          <input type="text" value={expTitle} onChange={txtTitle_Change} />
        </div>

        <div className="new-expense__control">
          <label>Amount</label>
          <input type="number" min="0.01" step="0.01" value={expAmount} onChange={txtAmount_Change} />
        </div>

        <div className="new-expense__control">
          <label>Date</label>
          <input type="date" min="2019-01-01" max="2022-12-31" value={expDate} onChange={txtDate_Change} />
        </div>
      </div>

      <div className="new-expense__actions">
        <button type="submit">Add Expense</button>
      </div>
    </form>
  );
};

export default ExpenseForm;
