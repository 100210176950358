import React, { useState } from 'react';

//import logo from './logo.svg';
//import ExpenseItem from "./components/ExpenseItem";
import Expenses from "./components/Expenses/Expenses";
import NewExpense from "./components/NewExpense/NewExpense";

import "./index.css";

const DUMMY_DATA = [
    {
      id: "e1",
      title: "Toilet Paper",
      amount: 94.12,
      date: new Date(2020, 7, 14),
    },
    { id: "e2", title: "New TV", amount: 799.49, date: new Date(2021, 2, 12) },
    {
      id: "e3",
      title: "Car Insurance",
      amount: 294.67,
      date: new Date(2021, 2, 28),
    },
    {
      id: "e4",
      title: "New Desk (Wooden)",
      amount: 450,
      date: new Date(2021, 5, 12),
    },
  ];

function App() {
  const [expenses, setExpenses] = useState(DUMMY_DATA);

  // const items = [
  //   {
  //     id: "e1",
  //     title: "Toilet Paper",
  //     amount: 94.12,
  //     date: new Date(2020, 7, 14),
  //   },
  //   { id: "e2", title: "New TV", amount: 799.49, date: new Date(2021, 2, 12) },
  //   {
  //     id: "e3",
  //     title: "Car Insurance",
  //     amount: 294.67,
  //     date: new Date(2021, 2, 28),
  //   },
  //   {
  //     id: "e4",
  //     title: "New Desk (Wooden)",
  //     amount: 450,
  //     date: new Date(2021, 5, 12),
  //   },
  // ];

  const onSaveExpenseData_Event = (expenseData) => {
    // console.log('App - onSaveExpenseData_Event');
    // console.log(expenseData);
    // setExpenses(items.push(expenseData));
    setExpenses((prevExpenseData) => { return [expenseData, ...prevExpenseData]});
  };

  return (
    <div>
      <NewExpense onSaveExpenseData={onSaveExpenseData_Event} />
      <Expenses expenses={expenses} />
    </div>
  );
}

export default App;
