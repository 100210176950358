import "./NewExpense.css";
import "./ExpenseForm";
import ExpenseForm from "./ExpenseForm";

const NewExpense = (props) => {
  const SaveExpenseData_Event = (enteredExpenseData) => {
    const expenseData = {
      ...enteredExpenseData,
      id: Math.random().toString(),
    };
    // console.log('Calling on the parent..!!!');
    // console.log('Parent Expense Data');
    // console.log(expenseData);

    if (typeof props.onSaveExpenseData !== typeof undefined) {
      props.onSaveExpenseData(expenseData);
    }
  };

  return (
    <div className="new-expense">
      <ExpenseForm onSaveExpenseData={SaveExpenseData_Event} />
    </div>
  );
};

export default NewExpense;
